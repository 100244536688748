.thumbnailWrap {
  text-align: center;
  // margin: 32px auto 11px;
  // aspect-ratio: 1/1.163;
  // width: 70.4%;
  aspect-ratio: 1/1;
  width: 93%;
  margin: 0 auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.productInfoWrap {
  padding: 0 20px 32px;
  border-bottom: 10px solid #f6f7f9;
  &.isMobile {
    padding: 0 20px 16px;
    .priceInfoWrap {
      margin-top: 10px;
    }
    .productName {
      font-size: 28px;
      line-height: 32px;
    }
  }
  .productName {
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    color: #131c31;
    padding: 10px;
    display: flex;
  }
  .scoreWrap {
    padding: 10px;
    display: flex;
    align-items: center;
    ul {
      display: flex;
      align-items: center;
      li img {
        width: 22px;
        height: 22px;
      }
    }
    .reviewCount {
      font-weight: 400;
      font-size: 17px;
      line-height: 20px;
      letter-spacing: -0.5px;
      color: #000000;
    }
  }
}

.priceInfoWrap {
  margin-top: 25px;
  .priceWrap {
    display: flex;
    align-items: center;
    & > span:first-child {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #6e6d79;
      padding: 10px 54px 10px 10px;
    }
    & > span:last-child {
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      text-decoration-line: line-through;
      color: #abb1bb;
      padding: 5px 0 5px 10px;
    }
    &.nosale {
      span:last-child {
        text-decoration: none;
      }
    }
  }
  .discountPriceWrap {
    display: flex;
    & > span:first-child {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #6e6d79;
      padding: 10px 54px 10px 10px;
      flex-shrink: 0;
    }
    & > span:last-child {
      display: flex;
      flex-direction: column;
      & > span:first-child {
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        color: #ff1e1e;
        padding: 5px 0 5px 10px;
      }
      & > .fromHospital {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.4px;
        color: #6e6d79;
        padding: 6px 10px;
      }
    }
  }
}

.optionWrap {
  padding: 32px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  &.isMobile {
    padding: 16px 20px;
    gap: 0;
    .option {
      display: block;
    }
  }
  .option {
    display: flex;
    align-items: center;
    & > span:first-child {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #6e6d79;
      width: 106px;
      height: 46px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      flex-shrink: 0;
    }
    .optionBox {
      flex-grow: 1;
      border: 1px solid #d1d0d5;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 21px 0px 0;
      height: 46px;
      background: #ffffff;
      cursor: pointer;
      position: relative;
      .optionList {
        position: absolute;
        bottom: 0;
        left: -1px;
        right: -1px;
        transform: translateY(100%);
        z-index: 1;
        li {
          height: 46px;
          display: flex;
          align-items: center;
          border: 1px solid #d1d0d5;
          border-bottom: none;
          background-color: white;
          padding-left: 10px;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: -0.5px;
          color: #131c31;
          &:last-child {
            border-bottom: 1px solid #d1d0d5;
          }
        }
        .adOptionWrap {
          padding: 0 24px 0 33px;
          display: flex;
          gap: 16px;
          align-items: center;
          height: 76px;
          border: 1px solid #d1d0d5;
          box-sizing: border-box;
          background-color: #fff500;
          &.isMobile {
            padding: 0 21px 0 16px;
            gap: 10px;
            height: 70px;
            @media screen and (max-width: 360px) {
              padding: 0;
              gap: 5px;
            }
            .imgWrap {
              img {
                width: 97px;
                height: 70px;
              }
            }
            .adContentWrap {
              flex-direction: column;
              align-items: start;
              .adNameWrap {
                span {
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 20px;
                  color: #3c1e1e;
                }
              }
              .directWrap {
                span {
                  font-weight: 600;
                  font-size: 10px;
                  line-height: 16px;
                }
              }
            }
          }
          .imgWrap {
            img {
              width: 102px;
              height: 76px;
            }
          }
          .adContentWrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            .adNameWrap {
              span {
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
              }
            }
            .directWrap {
              display: flex;
              gap: 8px;
              align-items: center;
              span {
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                color: #3c1e1e;
                text-align: right;
              }
              img {
                width: 14px;
                height: 14px;
              }
            }
          }
        }
      }
      & > span {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.5px;
        color: #abb1bb;
        padding: 9.5px 10px;
      }
      & > img {
        width: 22px;
        height: 22px;
      }
    }
  }
}
.stepContainer {
  display: block;
  justify-content: flex-start;
  &.isMobile {
    .stepWrap {
      width: 90%;

      .stepLeftWrap {
        flex-direction: column;
        align-items: start;
        & > span {
          font-size: 15px;
        }
      }
    }
  }
  .stepWrap {
    margin-left: auto;
    padding-right: 0px;
    cursor: pointer;
    padding: 0 30px 0 24px;
    margin-right: 20px;
    margin-bottom: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 727px;
    height: 76px;
    box-sizing: border-box;
    border-radius: 5px;
    background: #edf8ff;
    .stepLeftWrap {
      display: flex;
      gap: 12px;
      align-items: center;
      color: #3259e6;
      & > span {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: var(--theme-color);
      }
      div {
        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #666;
        }
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
    & > img {
      width: 47px;
      height: 44px;
    }
  }
}
.selectedOptionListWrap {
  padding: 0px 20px 32px;
  &.isMobile {
    padding: 0px 20px 16px;
  }
  li > div {
    display: flex;
    align-items: center;
    height: 62px;
    padding-right: 16px;
    background: #f6f7f9;
    width: 100%;
    margin-left: auto;
    box-sizing: border-box;
    &.isMobile {
      .optionName {
        font-size: 15px;
        line-height: 18px;
      }
      .counter {
        width: 90px;
        margin-right: 4px;
      }
      .editBox > span:first-child {
        font-size: 15px;
        line-height: 18px;
      }
    }
    .optionName {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: -0.5px;
      color: #131c31;
      padding: 10px;
      width: 350px;
      box-sizing: border-box;
    }
    .counter {
      display: flex;
      width: 110px;
      height: 42px;
      background: #ffffff;
      border: 1px solid #eeeeee;
      border-radius: 24px;
      box-sizing: border-box;
      flex-shrink: 0;
      &.noCounter {
        background: #d9d9d9;
        .minus,
        .plus {
          cursor: auto;
        }
      }
      & > div {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      }
      .minus,
      .plus {
        cursor: pointer;
        img {
          width: 12px;
          height: 12px;
        }
      }
    }
    .editBox {
      padding: 10px 0;
      display: flex;
      align-items: center;
      gap: 10px;
      margin-left: auto;
      .optionPrice {
        display: flex;
        flex-wrap: wrap;
        column-gap: 10px;
        justify-content: flex-end;
        span {
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: -0.5px;
          color: var(--theme-font-color);
          &:first-child {
            text-decoration: line-through;
            color: #abb1bb;
          }
        }
      }
      img {
        width: 17px;
        height: 17px;
        cursor: pointer;
      }
    }
  }
}
:global(.pc) .selectedOptionListWrap li > div {
  width: calc(100% - 106px);
}
.totalPriceWrap {
  border-top: 1px solid #f6f7f9;
  padding: 32px 0px;
  margin: 0px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 74px;
  &.isMobile {
    box-sizing: border-box;
  }
  .head {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #6e6d79;
    padding: 6px 16px 6px 10px;
  }
  .totalPrice {
    display: flex;
    align-items: flex-end;
    padding: 3px 0;
    & > span:first-child {
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      color: #131c31;
    }
    & > span:last-child {
      font-weight: 600;
      font-size: 16px;
      line-height: 25px;
      letter-spacing: -0.5px;
      color: #131c31;
    }
  }
}

.btnsWrap {
  display: flex;
  gap: 15px;
  height: 60px;
  padding: 0 20px;
  // padding: 0 20px 36px;
  & > div {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }
  .pickBtn {
    width: 68px;
    height: 100%;
    background: #ffffff;
    border: 1px solid #e9e9e9;
    border-radius: 2px;
    img {
      width: 30px;
      height: 30px;
    }
  }
  .basketBtn {
    background: #ffffff;
    border: 1px solid #131c31;
    border-radius: 2px;
    gap: 8px;
    flex-grow: 0.7;
    img {
      width: 30px;
      height: 30px;
    }
    span {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.5px;
      color: #131c31;
    }
  }
  .buyBtn {
    flex-grow: 1.3;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.5px;
    color: #ffffff;
    background-color: #131c31;
  }
}
.kakaoPlusBannerWrap {
  padding: 60px 20px;
  &.isMobile {
    padding: 36px 21px 44px;
  }
  & > img {
    cursor: pointer;
    width: 100%;
  }
  & > div {
    position: relative;
    padding: 0 26px;
    display: flex;
    align-items: center;
    background: #fae100;
    border-radius: 12px;
    height: 100px;
    .leftWrap {
      & > span {
        font-weight: 300;
        font-size: 18px;
        line-height: 24px;
      }
      .hitText {
        font-weight: 600;
      }
      .directWrap {
        margin-top: 4px;
        span {
          font-weight: 600;
          font-size: 12px;
          line-height: normal;
        }
        & > img {
          width: 14px;
          height: 14px;
        }
      }
    }
    & > img {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 168px;
      height: 100px;
    }
    @media screen and (max-width: 360px) {
      & > img {
        width: 158px;
        height: 98px;
      }
    }
    @media screen and (max-width: 350px) {
      & > img {
        width: 145px;
        height: 88px;
      }
    }
  }
}
.challengeSection {
  padding: 80px 20px 0;
  & > h3 {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    span:first-child {
      padding: 10px;
      color: #131c31;
      font-size: 28px;
      font-weight: 600;
      line-height: 40px; /* 142.857% */
    }
    .onGoing {
      padding: 2px 10px;
      color: #fff;
      font-size: 12px;
      font-weight: 700;
      border-radius: 5px;
      background: #ff3e3e;
    }
  }
  .challengeList {
    display: flex;
    flex-direction: column;
    li {
      display: flex;
      align-items: center;
      padding: 14px 13px 24px 20px;
      background: #f8f9fd;
      .challengeThumbnailWrap {
        width: 65px;
        height: 73px;
        border-radius: 10px;
        border: 1px solid #e5e5e5;
        overflow: hidden;
        box-sizing: border-box;
        flex-shrink: 0;
        margin-right: 18px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .challengeDetailWrap {
        display: flex;
        flex-direction: column;
        gap: 8px;
        flex-grow: 1;
        h4 {
          display: flex;
          align-items: center;
          gap: 10px;
          .challengeTitle {
            color: #131c31;
            font-size: 16px;
            font-weight: 400;
          }
        }
        p {
          color: #131c31;
          font-size: 14px;
          font-weight: 400;
        }
        & > button {
          color: #fff;
          font-size: 14px;
          font-weight: 400;
          letter-spacing: -0.5px;
          border: none;
          padding: 3px 8px 3px 11px;
          border-radius: 100px;
          background: rgba(19, 28, 49, 0.2);
          width: fit-content;
          cursor: pointer;
        }
      }
    }
  }
}
:global(.pc) .challengeSection {
  & > h3 {
    margin-bottom: 24px;
    span:first-child {
      font-size: 32px;
    }
  }
  .challengeList {
    display: flex;
    flex-direction: column;
    li {
      padding: 14px 18px 13px 20px;
      .challengeThumbnailWrap {
        width: 58px;
        height: 58px;
      }
      .challengeDetailWrap {
        h4 {
          gap: 10px;
          .challengeTitle {
            font-size: 20px;
          }
          .onGoing {
            padding: 2px 10px;
            border-radius: 5px;
            background: #ff3e3e;
            color: #fff;
            font-size: 12px;
            font-weight: 700;
          }
        }
      }
      & > button {
        padding: 15px 57px;
        border-radius: 5px;
        background: var(--concept, #131c31);
        border: none;
        cursor: pointer;
        color: #fff;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: -0.5px;
      }
    }
  }
}

.bannersWrap {
  padding: 145px 20px 124px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  // padding: 145px 20px 124px;
  padding: 0 20px 124px;
  &.isMobile {
    // padding: 64px 20px;
    padding: 0 20px 44px;
  }
  .kakaoEventBanner {
    background: #ffde31;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.5px;
      color: #131c31;
    }
  }
  .nonFaceProcess {
    &.isMobile {
      .banner {
        gap: 10px;
        height: 68px;
        span {
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          letter-spacing: -0.5px;
          color: #ffffff;
        }
        img {
          position: relative;
          top: unset;
          right: unset;
          transform: translateY(0);
          &.isOpen {
            transform: translateY(0) rotate(180deg);
          }
        }
      }
      .nonfaceProcessList {
        li {
          flex-direction: column;
          align-items: flex-start;
          padding: 10px;
          & > div:first-child {
            gap: 4px;
            .step {
              font-weight: 700;
              font-size: 15px;
              line-height: 18px;
              color: #131c31;
              width: unset;
              height: unset;
              padding: 2px 0;
              border: none;
            }
            span:nth-child(2) {
              font-weight: 700;
              font-size: 15px;
              line-height: 18px;
              color: #131c31;
              padding: 2px 0;
              margin: 0;
            }
          }
          & > span:last-child {
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            letter-spacing: -0.5px;
            color: #abb1bb;
            padding: 6px 0;
            margin: 0;
          }
        }
      }
    }
    .banner {
      height: 72px;
      background: #131c31;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      span {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.5px;
        color: #ffffff;
      }
      img {
        width: 24px;
        height: 24px;
        position: absolute;
        top: 50%;
        right: 21px;
        transform: translateY(-50%);
        &.isOpen {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }
    .nonfaceProcessList {
      background-color: #f8f9fd;
      li {
        display: flex;
        align-items: center;
        padding: 15px 22px;
        & > div:first-child {
          display: flex;
          align-items: center;
          width: 240px;
          .step {
            border: 1px solid #abb1bb;
            border-radius: 27px;
            font-weight: 700;
            font-size: 15px;
            line-height: 18px;
            letter-spacing: -0.5px;
            color: #131c31;
            width: 91px;
            height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          span:nth-child(2) {
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: -0.5px;
            color: #131c31;
            padding: 10px;
            margin-left: 10px;
          }
        }
        & > span:last-child {
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: -0.5px;
          color: #abb1bb;
          padding: 10px;
          margin-left: 30px;
        }
      }
    }
  }
}
:global(.pc) .navigationBar {
  gap: 37px;
  padding: 30px 0;
  li {
    width: 175px;
    height: 44px;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.5px;
    color: #abb1bb;
  }
}
.navigationBar {
  display: flex;
  justify-content: center;
  li {
    width: 111px;
    height: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.5px;
    color: #abb1bb;
    &.hit {
      font-weight: 700;
      color: #131c31;
    }
  }
}

.eventImgWrap {
  position: relative;
  img {
    width: 100%;
  }
  .eventBtnWrap {
    bottom: 6%;
    left: 15%;
    right: 15%;
    gap: 20px;
    display: flex;
    position: absolute;
    &.isMobile {
      bottom: 6%;
    }
  }
}

.globalPayEventWrap {
  position: relative;
  margin-bottom: 10px;
  img {
    width: 100%;
  }

  .globalPayEventBtnWrap1,
  .globalPayEventBtnWrap2 {
    position: absolute;
  }

  .globalPayEventBtnWrap1 {
    bottom: 45.7%;
    left: 10%;
    right: 10%;
  }

  .globalPayBtn2 {
    position: absolute;
    bottom: 5.2%;
    left: 10%;
    right: 10%;
  }

  .globalPayEventBtnWrap2 {
    bottom: 3%;
    left: 25%;
    right: 25%;
  }
}

.saladProductDetailWrap {
  position: relative;
  & > div {
    position: absolute;
  }
  .videoWrap {
    top: 5.5%;
  }
  .videoWrap2 {
    top: 19.02%;
  }
  .videoWrap3 {
    top: 30.61%;
  }
  .videoWrap4 {
    top: 42.31%;
  }
  .videoWrap5 {
    top: 58.71%;
  }
  .videoWrap6 {
    top: 65.54%;
  }
  .videoWrap7 {
    top: 69.6%;
  }
  .videoWrap8 {
    top: 84%;
  }
  .videoWrap21 {
    top: 22.7%;
  }
  .videoWrap22 {
    top: 40.5%;
  }
  .videoWrap23 {
    top: 58.91%;
  }
  .videoWrap24 {
    top: 82.49%;
  }
  .videoWrap25 {
    top: 91.1%;
  }
  .videoWrap31 {
    top: 0.2%;
  }
  .videoWrap32 {
    top: 32.55%;
  }
  .videoWrap33 {
    top: 63.8%;
  }
}

.biumProductDetailWrap {
  position: relative;
  & > div {
    position: absolute;
  }
  .videoWrap {
    top: 45.6%;
  }
  .videoWrap2 {
    top: 56%;
  }
  .videoWrap3 {
    top: 60.3%;
  }
  .videoWrap4 {
    top: 91.9%;
  }
}

.zeroProductDetailWrap {
  position: relative;
  & > div {
    position: absolute;
  }
  
  .newContentWrapper {
    top: 7.3%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 12%;
  }

  .imageAboveGif {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 35px;
    
    @media screen and (max-width: 768px) {
      width: 88%;
    }
  }

  .gifWrap {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 35px;
    
    @media screen and (max-width: 768px) {
      width: 88%;
    }
  }

  .imageBelowGif {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    
    @media screen and (max-width: 768px) {
      width: 88%;
    }
  }
  
  .videoWrap {
    top: calc(6.89% + 12% - 7.3%);
  }

  .videoWrap2 {
    top: calc(19.2% + 12% - 7%);
  }

  .videoWrap3 {
    top: calc(52.2% + 12% - 9%);
  }

  .videoWrap4 {
    top: calc(68% + 12% - 10.1%);
  }

  .videoWrap5 {
    top: calc(90.2% + 12% - 11.5%);
  }

  .videoWrap21 {
    top: calc(7.5% + 12% - 12%);
  }

  .videoWrap22 {
    top: calc(62.11% + 12% - 12%);
  }
}

.ccaProductDetailWrap,
.ccaProductDetailWrap2,
.ccaProductDetailWrap3 {
  position: relative;
  & > div {
    position: absolute;
  }
  .videoWrap {
    top: 7.1%;
  }
  .videoWrap2 {
    top: 10.37%;
  }
  .videoWrap3 {
    top: 22.19%;
  }
  .videoWrap4 {
    top: 34.37%;
  }
  .videoWrap5 {
    top: 51.29%;
  }
  .videoWrap6 {
    top: 58.35%;
  }
  .videoWrap7 {
    top: 77.35%;
  }
  .videoWrap21 {
    top: 9%;
  }
  .videoWrap22 {
    top: 25.2%;
  }
  .videoWrap23 {
    top: 45.68%;
  }
  .videoWrap24 {
    top: 70.82%;
  }
  .videoWrap31 {
    top: 8.83%;
  }
  .videoWrap32 {
    top: 52.44%;
  }
}

.dietDetailWrap {
  position: relative;
  .backImg {
    width: 100%;
  }
  & > div {
    position: absolute;
  }
  .videoWrap {
    top: 2%;
  }
  .videoWrap2 {
    top: 7.8%;
  }
  .videoWrap3 {
    top: 22.8%;
  }
  .videoWrap4 {
    top: 26.8%;
  }
  .videoWrap5 {
    top: 29.67%;
  }
  .videoWrap6 {
    top: 46.1%;
  }
  .videoWrap21 {
    top: 94.5%;
  }
  .videoWrap31 {
    top: 86.5%;
  }
}
.appBtnWrap {
  padding: 0 10% 7%;
  display: flex;
  gap: 13px;
  background: #f5f5f5;
  img {
    cursor: pointer;
    width: calc(100% / 2 - 6.5px);
  }
}

.productDetail {
  img {
    margin: 0 auto;
    max-width: 100%;
  }
  .solution {
    padding: 62px 20px 133px;
    & > span:first-child {
      display: inline-block;
      padding: 10px 26.5px;
      border: 2px solid #131c31;
      border-radius: 28px;
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
      color: #131c31;
      margin-left: 20px;
    }
    &.mobileSolution {
      & > span:first-child {
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #131c31;
        margin-left: 0;
      }
      & > p:nth-child(2) {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #131c31;
        padding: 20px 0;
        margin: 0;
      }
      & > p:nth-child(3) {
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #131c31;
        padding: 5px 0px;
        margin: 0 0 70px;
      }
    }
    & > p:nth-child(2) {
      font-weight: 700;
      font-size: 48px;
      line-height: 58px;
      color: #131c31;
      padding: 10px 20px;
      margin-top: 38px;
    }
    & > p:nth-child(3) {
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
      color: #131c31;
      padding: 30px 20px;
    }
    .reviewSliderWrap {
      background: #131c31;
      & > span {
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        color: #ffffff;
        display: inline-block;
        padding: 40px 20px;
      }
      .reviewSlider {
        position: relative;
        :global .swiper {
          // padding-left: 20px;
          :global .swiper-wrapper {
            cursor: grab;
            &:active {
              cursor: grabbing;
            }
            & > div {
              height: auto;
            }
          }
        }
        :global .swiper-scrollbar {
          background: rgba(255, 255, 255, 0.2);
          height: 4px;
          margin: 36px 10px;
          cursor: pointer;
          & > div {
            background-color: white;
            height: 100%;
          }
        }
        .slideWrap {
          padding: 0 13px;
          height: 100%;
          display: flex;
          flex-direction: column;
          @media screen and (min-width: 700px) {
            padding: 0 8px;
          }
          img {
            width: 100%;
            aspect-ratio: 1/1.115;
            object-fit: cover;
          }
          .reviewInfo {
            background-color: white;
            padding: 10px 10px 13px;
            display: flex;
            flex-direction: column;
            min-height: 171px;
            flex-grow: 1;
            .content {
              padding: 20px 10px;
              font-weight: 400;
              font-size: 20px;
              line-height: 24px;
              color: #131c31;
              flex-grow: 1;
            }
            .userInfoWrap {
              padding: 5px 0;
              display: flex;
              justify-content: flex-end;
              & > span:first-child {
                font-weight: 700;
                font-size: 16px;
                line-height: 19px;
                color: #131c31;
                padding: 4px 10px;
                display: inline-block;
              }
              & > span:last-child {
                font-weight: 700;
                font-size: 16px;
                line-height: 19px;
                color: #abb1bb;
                padding: 4px 10px;
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }
}

.productInfoArea {
  border-top: 10px solid #f8f9fd;
  padding: 0px 20px 64px;
  & > h3 {
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    color: #131c31;
    padding: 10px 0;
    margin: 32px 0;
  }
  .deliveryInfo {
    & > h4 {
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      color: #131c31;
      padding: 10px;
    }
    .deliveryInfoList {
      padding: 16px 0;
      li {
        padding: 10px;
        display: flex;
        align-items: flex-start;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: rgba(19, 28, 49, 0.5);

        span:first-child {
          width: 96px;
          flex-shrink: 0;
        }
      }
    }
  }
  .exchangeInfo {
    & > h4 {
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      color: #131c31;
      padding: 10px;
      margin-bottom: 16px;
    }
    & > span {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      display: inline-block;
      padding: 10px;
      color: rgba(19, 28, 49, 0.5);
    }
  }
  .globalLegitScriptWrap {
    position: relative;
    height: 80px;
    .legitLogo {
      position: absolute;
      right: 0;
      max-width: 100px;
    }
  }
}

:global(.pc) .fixedContents {
  // top: 48px;
  top: 0;
  z-index: 100;
  &.adExist {
    top: 100px;
    top: 52px;
  }
}
.fixedContents {
  position: fixed;
  top: 62px;
  &.adExist {
    // top: 114px;
    top: 53px;
    // top: 0;
  }
  left: 50%;
  transform: translateX(-50%);
  max-width: 850px;
  width: 100%;
  z-index: 99;
  background-color: white;
  .productInfoSection {
    padding: 9px 21px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #efefef;
    .left {
      display: flex;
      .productImg {
        width: 68px;
        height: 68px;
        border: 1px solid #e9e9e9;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .productInfo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        & > span:first-child {
          font-weight: 600;
          font-size: 17px;
          line-height: 20px;
          color: #131c31;
          padding: 7px 10px;
        }
        .priceInfo {
          display: flex;
          gap: 8px;
          align-items: flex-end;
          padding: 2px 10px;
          .discountPrice {
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #131c31;
          }
          .price {
            font-weight: 600;
            font-size: 13px;
            line-height: 16px;
            display: flex;
            text-decoration-line: line-through;
            color: #abb1bb;
          }
        }
      }
    }
    .formOpenBtn {
      width: 180px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #131c31;
      border-radius: 5px;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: -0.5px;
      color: #ffffff;
      cursor: pointer;
    }
    .formCloseBtn {
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      color: #131c31;
      padding: 14.5px 21.5px;
      cursor: pointer;
    }
  }
  .optionSelectSection {
    background: #f6f7f9;
    display: flex;
    padding-bottom: 51px;
    position: relative;
    .left {
      width: 50%;
      padding: 34px 20px 0;
      box-sizing: border-box;
      .optionSelectList {
        display: flex;
        flex-direction: column;
        gap: 10px;
        li {
          display: flex;
          align-items: center;
          & > span {
            font-weight: 600;
            font-size: 15px;
            line-height: 18px;
            color: #6e6d79;
            padding: 9px 6.5px;
            margin-right: 36px;
          }
          .optionBox {
            background: #ffffff;
            border: 1px solid #d1d0d5;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-grow: 1;
            padding: 0 13px 0 10px;
            height: 36px;
            cursor: pointer;
            position: relative;
            .optionList {
              position: absolute;
              bottom: 0;
              left: -1px;
              right: -1px;
              transform: translateY(100%);
              z-index: 1;
              li {
                height: 36px;
                display: flex;
                align-items: center;
                border: 1px solid #d1d0d5;
                border-bottom: none;
                background-color: white;
                padding-left: 10px;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                letter-spacing: -0.5px;
                color: #131c31;
                &:last-child {
                  border-bottom: 1px solid #d1d0d5;
                }
              }
            }
            span {
              font-weight: 400;
              font-size: 14px;
              line-height: 17px;
              letter-spacing: -0.5px;
            }
            img {
              width: 22px;
              height: 22px;
            }
          }
        }
      }
      .selectedOptionList {
        margin-top: 21px;
        li {
          padding: 5.5px 10px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 8px;
          .productName {
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            letter-spacing: -0.5px;
            color: #131c31;
            flex-grow: 1;
          }
          .counter {
            display: flex;
            background: #ffffff;
            border: 1px solid #eeeeee;
            border-radius: 24px;
            flex-shrink: 0;
            height: 35px;
            .minus {
              display: flex;
              justify-content: center;
              align-items: center;
              padding-left: 16px;
              cursor: pointer;
              img {
                width: 12px;
                height: 12px;
              }
            }
            .number {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 19px;
            }
            .plus {
              display: flex;
              justify-content: center;
              align-items: center;
              padding-right: 16px;
              cursor: pointer;

              img {
                width: 12px;
                height: 12px;
              }
            }
          }
          .price {
            display: flex;
            align-items: center;
            gap: 10px;
            width: 90px;
            justify-content: flex-end;
            flex-shrink: 0;
            .detail {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              span {
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                letter-spacing: -0.5px;
                color: var(--theme-font-color);
                &:first-child {
                  text-decoration: line-through;
                  color: #abb1bb;
                }
              }
            }
            img {
              width: 15px;
              height: 15px;
              cursor: pointer;
            }
          }
        }
      }
    }
    .centerLine {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      width: 1px;
      background-color: #d9d9d9;
      margin: 15px 0;
    }
    .right {
      width: 50%;
      padding: 15px 38px 0 10px;
      // width: 100%;
      // padding: 15px 38px 0 0;
      box-sizing: border-box;
      .totalPrice {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0;
        & > span:first-child {
          font-weight: 600;
          font-size: 15px;
          line-height: 18px;
          color: #6e6d79;
          padding: 6px 0;
        }
        .price {
          display: flex;
          align-items: flex-end;
          padding: 3px 10px;
          & > span:first-child {
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            color: #131c31;
          }
          & > span:last-child {
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: -0.5px;
            color: #131c31;
            padding-bottom: 2px;
          }
        }
      }
      .fixedFormBtnsWrap {
        display: flex;
        row-gap: 15px;
        column-gap: 16px;
        flex-wrap: wrap;
        .buyBtn {
          width: 100%;
          height: 48px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #131c31;
          border-radius: 5px;
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          letter-spacing: -0.5px;
          color: #ffffff;
          cursor: pointer;
        }
        .pickBtn {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 6px;
          flex-grow: 1;
          background: #ffffff;
          border: 1px solid #d9d9d9;
          border-radius: 5px;
          height: 48px;
          cursor: pointer;
          img {
            width: 20px;
            height: 20px;
          }
          span {
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            letter-spacing: -0.5px;
            color: #131c31;
          }
        }
        .basketBtn {
          flex-grow: 1;
          background: #ffffff;
          border: 1px solid #131c31;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 5px;
          height: 48px;
          cursor: pointer;

          img {
            width: 20px;
            height: 20px;
          }
          span {
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            letter-spacing: -0.5px;
            color: #131c31;
          }
        }
      }
    }
  }
  .fixedNavigationBar {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border-bottom: 1px solid #efefef;
    li {
      width: 111px;
      height: 39px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: -0.5px;
      color: #abb1bb;
      cursor: pointer;
      &.hit {
        font-weight: 700;
        line-height: 24px;
        letter-spacing: -0.5px;
        color: #131c31;
      }
    }
  }
}
:global(.pc) .fixedNavigationBar li {
  width: 175px;
  height: 68px;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.5px;
  color: #abb1bb;
}

.mobileBottomTab {
  position: fixed;
  // bottom: calc(env(safe-area-inset-bottom) + 64px);
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 850px;
  background-color: white;
  z-index: 999;
  .contentArea {
    background: #f6f7f9;
    padding: 0 21px 0px;
    max-height: 0px;
    overflow: scroll;
    position: relative;
    transition: all 0.4s linear;
    &::-webkit-scrollbar {
      display: none;
    }
    &.isOpen {
      max-height: 442px;
      padding: 0 21px 30px;
    }
    .closeBtnArea {
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: sticky;
      top: 0;
      background-color: #f6f7f9;
      img {
        width: 30px;
        height: 30px;
      }
    }
    .optionSelect {
      .option {
        & > span:first-child {
          display: flex;
          height: 46px;
          align-items: center;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          color: #6e6d79;
        }
        & > .optionBox {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 11px 0 10px;
          height: 46px;
          background: #ffffff;
          border: 1px solid #d1d0d5;
          box-sizing: border-box;
          cursor: pointer;
          & > span {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: -0.5px;
            color: rgb(171, 177, 187);
          }
          & > img {
            width: 22px;
            height: 22px;
          }
        }
        .optionList {
          display: flex;
          flex-direction: column;
          li {
            border: 1px solid #d1d0d5;
            box-sizing: border-box;
            height: 46px;
            display: flex;
            align-items: center;
            padding: 0 11px 0 10px;
            background-color: white;
            border-top: none;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: -0.5px;
            color: #131c31;
          }
        }
      }
    }
    .selectedOptionList {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-top: 16px;
      .selectedOption {
        background-color: white;
        background: #ffffff;
        border: 1px solid #d1d0d5;
        padding-bottom: 10px;
        .top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px;
          span {
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            letter-spacing: -0.5px;
            color: #131c31;
          }
          img {
            width: 17px;
            height: 17px;
          }
        }
        .bottom {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 3px 10px;
          .counterBox {
            display: flex;
            align-items: center;
            background: #ffffff;
            border: 1px solid #eeeeee;
            width: 110px;
            height: 42px;
            &.noCounterBox {
              background: #d9d9d9;
              .minus,
              .plus {
                cursor: auto;
              }
            }
            .count {
              width: 24px;
              height: 24px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: 400;
              font-size: 14px;
              line-height: 17px;
              letter-spacing: -0.5px;
              color: #000000;
            }
            .minus,
            .plus {
              flex-grow: 1;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;
              img {
                width: 12px;
                height: 12px;
              }
            }
          }
          .optionPrice {
            display: flex;
            column-gap: 10px;
            & > span {
              font-weight: 400;
              font-size: 16px;
              line-height: 19px;
              letter-spacing: -0.5px;
              color: var(--theme-font-color);
              &:first-child {
                text-decoration: line-through;
                color: #abb1bb;
              }
            }
          }
        }
      }
    }
    .totalPriceInfoWrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 74px;
      & > span {
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        color: #6e6d79;
      }
      .totalPrice {
        display: flex;
        align-items: flex-end;
        & > span:first-child {
          font-weight: 600;
          font-size: 24px;
          line-height: 29px;
          color: #131c31;
        }
        & > span:last-child {
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: -0.5px;
          color: #131c31;
          padding-bottom: 3px;
        }
      }
    }
  }
  .notOpened {
    height: 64px;
    display: flex;
    gap: 5px;
    align-items: center;
    padding: 0 21px;
    .pickBtn {
      border: 1px solid #e9e9e9;
      border-radius: 2px;
      width: 58px;
      height: 50px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      img {
        width: 24px;
        height: 24px;
      }
    }
    .openBtn {
      background: #131c31;
      border: 1px solid #131c31;
      border-radius: 2px;
      box-sizing: border-box;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.5px;
      color: #ffffff;
      flex-grow: 1;
    }
  }
  .opened {
    display: flex;
    height: 64px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 0 21px;
    &.buyOpend {
      .buyBtn {
        width: 100%;
      }
    }
    .basketBtn {
      background: #ffffff;
      border: 1px solid #131c31;
      border-radius: 2px;
      box-sizing: border-box;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.5px;
      color: #131c31;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      width: calc(50% - 2.5px);
    }
    .buyBtn {
      background: #131c31;
      border: 1px solid #131c31;
      border-radius: 2px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.5px;
      color: #ffffff;
      height: 50px;
      width: calc(50% - 2.5px);
      &.noBasketBuyBtn {
        width: 100%;
      }
    }
  }
}
